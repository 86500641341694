
import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { ListViewStyled, PropsStyled } from "./ListViewStyled";

type Props = {
  data: Record<string, any>[];
  fieldNameText?: string;
  fieldNameIcon?: string;
  type?: string;
  iconUrl?: string;
  key: string;
  number_field?: string;
  children?: ( url: string, idx:number, item:Record<string, any> ) => ReactNode;
} & PropsStyled;

const ListView = ({ children, data, type = 'marker',number_field = "" ,key, fieldNameText = 'text', fieldNameIcon = "icon", iconUrl, styleProps = {width: '170px'}}: Props) => {
  var c1= () => <> </>;
  var c2 = (x: any) => <> {x}</>;

  if(type === 'icon') {
    c1 =() => <div className="c_img"> <img src={iconUrl} alt="" />   </div>
    c2 = (x: any) => <div className="c_1"> {x}</div>;
  }
    return (
    <ThemeProvider theme={{ type: type }}>
      <ListViewStyled styleProps={styleProps} >
        <ul>
          {data.map((item, idx) => (
            <li key={item[key]} >
              {c1()}
              {item[number_field] && <div className="c_l">{item[number_field] } </div>}
              {item[fieldNameText] && c2(item[fieldNameText]) }
              {children && children(item?.UrlSpot, idx, item)}
              </li>
          ))}
        </ul>
      </ListViewStyled>
    </ThemeProvider>
    )

}
 

export default ListView;
import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthenticatedRoute, checkAuthenticatedSession, isAuthenticated } from './components/Authentication';
import ExtractReport from './pages/ExtractReport';
import LoginBananas from './pages/LoginBananas';
import { dev } from './std';


const App: React.FC = () => {
  dev.log(isAuthenticated());
  dev.log(checkAuthenticatedSession());
  
  // const SessionInfo = checkAuthenticatedSession();


  return (
    <Router >
      <Routes>
        <Route path="/" element={<LoginBananas />} />;
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/Home" element={
          <AuthenticatedRoute
            component={(parm) => 
               <ExtractReport Clients={parm.data} /> 
            }
          />} />

   
        
        {/*
        <Route path="/Home2" element={<Home />} />;
        <Route path="/Login2" element={<Login />} />;*/}
      </Routes>
    </Router>

  );
};

export default App;


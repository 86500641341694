import React from 'react';
import styled from 'styled-components';

interface OverlayProps {
    isOpen: boolean;
    onClose: () => void;
    zIndex?: number;
    children?: React.ReactNode;
}

const Overlay: React.FC<OverlayProps> = ({ isOpen, onClose, zIndex = 1000, children }) => {
    if (!isOpen) return null;

    return (
        <OverlayStyled 
            className="overlay" 
            style={{ zIndex: zIndex }}
            onClick={onClose}
        >
            <div className="overlay-content" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </OverlayStyled>
    );
};

export default Overlay;

const OverlayStyled = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`
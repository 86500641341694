import React from "react";
import { ThemeProvider } from "styled-components";
import WindowComponentStyled from "./WindowComponentStyled";

export interface ThemeWindowComponent {
    width: number | string;
    backgroundColorTitle?: string
  }

type Props = {
    title: string;
    children?: React.ReactNode;
    width?: number | string;
    show?: boolean
    backgroundColorC?: string
    backgroundColorTitle?: string
    colorTitle?: string
    logo?: string
    theme?: ThemeWindowComponent
    maxHeight?: number
}

  
const themeDefault: ThemeWindowComponent = {
    width: '100%',
    backgroundColorTitle: "rgb(33, 33, 33)",
  };

const WindowComponent = ({ title, children, show, backgroundColorC, logo, maxHeight, backgroundColorTitle , colorTitle, theme = themeDefault}: Props) => {
    return (
        <ThemeProvider theme={theme}>
        <WindowComponentStyled  show={show} backgroundColorC={backgroundColorC}
            maxHeight={maxHeight} backgroundColorTitle={backgroundColorTitle} ColorTitle={colorTitle}>
            <div className="c">
                <div className="t">
                    {logo ?
                        <div className="c_logo">
                            <img src={logo} alt="" />
                        </div> : <></>}
                    {title}</div>

                <div className="c_1" style={{ width: "auto" }}>
                    {children}
                </div>
            </div>
        </WindowComponentStyled >
        </ThemeProvider>
    )
}

export default WindowComponent;
import { Bar, BarChart, CartesianGrid, LabelList, Tooltip, XAxis, YAxis } from 'recharts';
import GraphicStyled from './GraphicStyled';



type DataItem = {
    name: string;
    uv: number;
    pv: number;
    amt: number;
}

type State = {}

/*
const data = [
    {
        name: 'Loja 001 - Shopping da Varra - Rio de Janeiro',
        uv: 7000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Loja 001 - Shopping da Varra - Rio de Janeiro',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },

    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },


];*/

const textStyle = { fill: '#fff' };

type Props<T> = {
    show: boolean;
    dataKeyBar?: string;
    data:T[];
    dataKeyXAxis?: string;
    Alias?: string;
}

const Graphic = <T, >({ show, dataKeyBar = "uv", dataKeyXAxis = "name", data, Alias = "" }: Props<T>) => {
    // var demoUrl = 'https://codesandbox.io/s/simple-bar-chart-tpz8r';
    //width={2000}
    return (
        <>
            {
                data.length > 0 &&
                <GraphicStyled >
                    <div className="scrollable-container">
                        <BarChart
                            width={(data.length * 100) + 96 }
                            height={400}
                            data={data}
                            margin={{
                                top: 15,
                                right: 30,
                                left: 5,
                                bottom: 0
                            }}
                           
                   
                        >
                            <CartesianGrid strokeDasharray="0" />
                            <XAxis
                                dataKey={dataKeyXAxis}
                                height={60}
                                tick={{ fill: '#fff', width: 100, fontSize: 12 }}
                                interval={0}
                            />
                            <YAxis tick={textStyle} />
                            <Tooltip />
                            {/*<Legend />*/}
                            <Bar dataKey={dataKeyBar}
                                fill="#fac355"
                                name={Alias}
                               
                                
                            >
                                <LabelList dataKey="uv" position='insideTop' fill="#000" />
                            </Bar>
                        </BarChart>
                    </div>
                </GraphicStyled>
            }


        </>
    )
}


export default Graphic;
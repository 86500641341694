interface Std {
    formatEval: (str: any) => any;
    formatDate: (date: Date ) => string;
    isNull: (a: any ) => boolean;
    isDevelopment: boolean;
}

interface Dev{ 
    log: (...args: any[]) => any
}


const dev:Dev = {
    log: (...args) => {
        if (std.isDevelopment)   console.log(...args);
      }
}  

const std: Std = {
    isDevelopment: process.env.NODE_ENV === 'development',
    formatEval: (str: any) => {
        if (typeof str === 'string') {
            return eval("(" + str + ")");
        } else {
            return str;
        }
    },
    isNull: (a: any): boolean => {
        return a === null || a === undefined ;
    },
    formatDate: (date: Date ): string => {
    //    if(!std.isNull(date)) {
            const year: number = date.getFullYear();
            let month: number | string = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month;
            let day: number | string = date.getDate();
            day = day < 10 ? '0' + day : day;
            return `${year}-${month}-${day}`
      //  }
     //   return ''
    }
};

export { dev, std };


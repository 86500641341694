import { AxiosError, AxiosResponse, Method } from 'axios';
import { useEffect, useState } from 'react';
import { axiosGet, axiosPost } from '../Ajax';

type Data = any;

interface RequestParams {
  [key: string]: any;
}

const useFetchData = <TData = Data>(
  url: string,
  method: Method = 'get',
  params?: RequestParams
) => {
  const [data, setData] = useState<TData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: AxiosResponse<TData> =
          method === 'get'
            ? await axiosGet(url, { params })
            : await axiosPost(url, params);
        setData(response.data);
        setError(null);
      } catch (error) {
        setError((error as AxiosError).message || 'Erro na requisição.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, method, params]);

  return { data, loading, error };
};

export default useFetchData;
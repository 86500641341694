import axios, { AxiosRequestConfig } from "axios";
import { std } from "./std";

const api = axios.create({
    baseURL:std.isDevelopment ? 'http://localhost:50002' : '/',
    //timeout: 10000,
    withCredentials: true,
    
});
  
const axiosGet = async (url: string, params?: any, config?: AxiosRequestConfig) => {
    try {
        return  await api.get(url, { params, ...config });
    } catch (error) {
        throw error;
    }
};

const axiosPost = async (url: string, data?: object, config?: object) => {
  try {
    return await api.post(url, data, config);
    } catch (error) {
        throw error;
    }
};

export { axiosGet, axiosPost };



